import React, { useState, useEffect } from "react";
import DataService from "../../services/api/data_services";
import Layout from "../../components/layouts/layout";
import Infoview from "../../templates/InfoView";
import Megaview from "../../templates/MegaView";
import Miniview from "../../templates/MiniView";
import Button from "../../components/Button";
import RightArrow from "../../assets/Icons/go.svg";
import clutchLogo from "../../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import LeftGrid from "../../templates/LeftGrid";
import { BASE_SITE_URL } from "../../shared/constants";
//Image Banner
import ImageBanner from "../../templates/ImageBanner";

//Mini Image Banner
import MiniImageBanner from "../../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../../assets/Images/contact_us_bg.png";
import Typography from "@material-ui/core/Typography";
import BackArrowIcon from "../../assets/Images/accordian.svg";

//Two column contact info
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import Clm1Img from "../../assets/Icons/phone.svg";
import Clm2Img from "../../assets/Icons/mail.svg";

import ImageBnr from "../../assets/Images/Industry/Hospitality/Hospitality@2x.png";
import ogimage from "../../assets/Images/ogImages/Hospitality@2x.png";

const Hospitality = () => {
  const [page, setPage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articlePage, setArticlePage] = useState(1);
  const [articleLimit, setArticleLimit] = useState(6);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-industries?type=White Paper&industry_type=Hospitality"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${page}&type=Article&industry_type=Hospitality`
    ).then((response) => {
      setArticleData(response.data);
      setArticleRawData(response);
    });
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${page}&type=Client Story&industry_type=Hospitality`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  /**
   * Image Banner // HIDING TEMPORATILY
   */
  const ImageBannerData = {
    bnrImg: ImageBnr,
    altImg: ImageBnr,
    // component:'Who',
    bnrImgTitle: <> Hospitality</>,
    bnrImgSubTitle: "Industries",
    servicesColumn1: {
      data: [
        {
          service: "Startups",
          link: "/who-we-work-with/startups",
        },
        {
          service: "Finance",
          link: "/who-we-work-with/finance",
        },
        {
          service: "Hospitality",
          link: "/who-we-work-with/hospitality",
        },
        {
          service: "Not-For-Profit",
          link: "/who-we-work-with/not-for-profit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Healthcare",
          link: "/who-we-work-with/healthcare",
        },
        {
          service: "Education",
          link: "/who-we-work-with/education",
        },
        {
          service: "Real Estate & PropTech",
          link: "/who-we-work-with/real-estate-and-proptech",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Retail and E-commerce",
          link: "/who-we-work-with/retail-and-e-commerce",
        },
        {
          service: "Media and Publishing",
          link: "/who-we-work-with/media-and-publishing",
        },
        {
          service: "Public Sector",
          link: "/who-we-work-with/public-sector",
        },
      ],
    },
  };
  /**
   * Mini Image Banner
   */

  const miniImageBannerData = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };
  const InfoviewData = {
    route: "/what-we-do",
    color: "primary",
    tooltip: "yes",
    width: "11%",
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Hos</span>pitality
      </div>
    ),
    InfoviewContent: (
      <>
        Understanding how travelers search, shop, and book rooms leads to better
        conversion and more online revenue. We are experts in creating tailor
        made digital solutions and optimized applications that attract and
        convert travel shoppers. We employ the latest design, usability,
        personalization, and search technology to help our clients transform
        their customer experience, enable innovation, drive operational
        efficiencies and revenue growth.This ensures that your dynamic
        omnichannel digital experience can be found online and easy to navigate,
        and inspires more visitors to book directly while improving internal
        operational efficiency. Partner with Digiryte today!
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  return (
    <Layout
      seo={{
        title: "Digiryte: UK’s Leading Software Consultancy",
        ogtitle: "Digiryte: UK’s Leading Software Consultancy",
        description:
          "We are UK’s leading software consultancy ,building next generation applications and digital products to meet your business goals.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/who-we-work-with"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Who we work with
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>

      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <h2 className="title-header">
            {" "}
            <span className="red-line3letter red-3letter-mbl">Fea</span>tured
            Insights
          </h2>
          <div className="mt-50"></div>
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-50">
          <Miniview
            miniviewData={clientStoryData}
            navigateComponent={<LeftGrid />}
          />
        </div>
      )}
      {clientStoryRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE CLIENT STORIES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              routepath="/who-we-work-with"
              label="MORE CLIENT STORIES"
              tip="MORE CLIENT STORIES"
              tooltip="yes"
            />
          </div>
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <MiniImageBanner miniImageBannerData={miniImageBannerData} />

      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default Hospitality;
